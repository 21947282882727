import { defineNuxtRouteMiddleware } from '#imports'

export default defineNuxtRouteMiddleware((to, from) => {
	const routeTo = to.fullPath
	const routeFrom = from.fullPath

	const routeMap: { [key: string]: number } = {
		'/karaoke': 1,
		'/songs': 2,
		'/my-library': 3
	}

	const getRouteValue = (route: string) => {
		for (const key in routeMap) {
			if (route.includes(key)) {
				return routeMap[key]
			}
		}
		return 0
	}

	const toValue = getRouteValue(routeTo)
	const fromValue = getRouteValue(routeFrom)

	// If going right to left, slide right. And vice versa.
	// If a page is accessed directly, use fade.
	if (toValue !== fromValue) {
		if (to.meta.pageTransition && typeof to.meta.pageTransition === 'object') {
			if (fromValue === 0) {
				to.meta.pageTransition.name = 'fade'
			} else if (fromValue < toValue) {
				to.meta.pageTransition.name = 'slide-left'
			} else if (fromValue > toValue) {
				to.meta.pageTransition.name = 'slide-right'
			}
		}
	}
})
